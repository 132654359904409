<template>
  <div class="mx-auto overflow-auto conteneur">
    <div class="gauche">
      <div class="mx-4">
        <small class="text-muted">
          {{ clusters["clusters"].length }} clusters
        </small>
        /
        <small class="text-muted">
          {{ clusters["serviceInfo"]["clusteringTimeMillis"] }} ms clustering
          time
        </small>
      </div>

      <ul class="listTag overflow-auto">
        <li
          class="miseEnPage my-2 badge text-wrap"
          v-for="cluster in this.reponse_carrot['clusters']"
          v-on:click="set_sort_click(cluster)"
          v-bind:key="cluster['labels'][0]"
          v-bind:class="cluster['class']"
        >
          {{ cluster["labels"][0] }} ({{ cluster["documents"].length }} docs)
        </li>
      </ul>
    </div>
    <div class="vr"></div>
    <div class="droite">
      <presentationResultatRecherche />
    </div>
  </div>
</template>
<style scoped>
.conteneur {
  display: flex;
  height: calc(100vh - 120px);
}
.gauche {
  width: 40%;
}
.droite {
  width: 60%;
}
.listTag {
  height: calc(100vh - 145px);
  width: 100%;
  border: 0;
  padding: 0;
  margin-bottom: 0;
}
.badge {
  display: block;
  color: black;
}
.choix {
  background-color: #22609e;
  color: white;
}
</style>
<script>
import { mapState } from "vuex";
import presentationResultatRecherche from "@/components/presentation_resultatRecherche.vue";

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export default {
  name: "list",
  components: { presentationResultatRecherche },
  data: function () {
    return {
      currentTab: "list",
    };
  },
  computed: {
    ...mapState(["reponse_carrot", "sort"]),
    clusters: function () {
      return this.reponse_carrot;
    },
  },
  watch: {
    sort: function () {
      this.set_sort_click({ labels: [] });
    },
  },
  methods: {
    set_sort_click: function (params) {
      let list = this.sort["list"],
        labels = this.sort["labels"];

      if (params["labels"].length == 0) {
        if (list.length == 0) {
          this.reponse_carrot["clusters"].forEach((element) => {
            element["class"] = "";
          });
          this.$forceUpdate();
        }
        return;
      }

      if (params["class"] == "choix") {
        params["class"] = "";
        list = list.filter((x) => !params["documents"].includes(x));
        labels.shift(params["labels"][0]);
      } else {
        params["class"] = "choix";
        list = list.concat(params["documents"]);
        labels.push(params["labels"][0]);
      }

      this.$store.commit("set_sort", {
        list: list.filter(onlyUnique),
        labels: labels,
        id: -1,
      });
      this.$forceUpdate();
    },
  },
};
</script>
